import { ActividadPreventiva } from 'entities/actPrev';
import { autoinject, bindable, bindingMode, containerless, observable, computedFrom } from 'aurelia-framework';
import { Api } from 'utils/api';
import { EmpresaCdT } from 'entities/centro';
import { ActividadPreventivaEdit } from 'pages/actividadPreventiva/edit';

@containerless()
@autoinject
export class EyActPrevInforme {
  @bindable empresaCdt: EmpresaCdT;
  @bindable actPrev: ActividadPreventiva;
  @bindable parent: ActividadPreventivaEdit;

  constructor(private api: Api) {
  }

  attached() {
    this.parent.getTrabajadores();
    this.api.updateTiposInforme();
  }
}
