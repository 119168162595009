import { retryStrategy } from 'aurelia-fetch-client';
import { Accidente, getAccConSinBaja, getAccLugar, getAccTipoSucesos, TrabajadorAccidente, FormaDeContacto, AgenteMaterial } from './../entities/actPrev';
import { autoinject, containerless, observable } from 'aurelia-framework';
import { Trabajador } from 'entities/trabajador';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import { DialogController } from 'aurelia-dialog';
import { EyCdtGestion } from './ey-cdt-gestion';
import { EmpresaCdT } from 'entities/centro';
import moment from 'moment';
import { EyDialog } from './ey-dialog';


@containerless()
@autoinject
export class EyAccTrabForm extends EyDialog {

  @observable acc: Accidente;
  @observable selectedTrab: Trabajador;
  @observable trabAcc: TrabajadorAccidente;
  @observable trabList: Trabajador[] = [];
  title: string;
  @observable errors: string;
  parent: EyCdtGestion;
  empresaCdt: EmpresaCdT;

  //
  @observable tipoSucesoList: any = [];
  @observable tipoLugarList: any = [];
  @observable tipoConSinBajaList: any = [];

  @observable formaDeContactoList: FormaDeContacto[] = [];
  // @observable agenteLesionList: FormaDeContacto[] = [];

  constructor(api: Api, element: Element, private ea: EventAggregator, private controller: DialogController) {
    super(api, element);
  }

  activate(model) {
    this.acc = model.acc;
    this.trabAcc = model.trabAcc;
    this.parent = model.parent;
    this.empresaCdt = model.empresaCdt;

    this.tipoSucesoList = getAccTipoSucesos();
    this.tipoLugarList = getAccLugar();
    this.tipoConSinBajaList = getAccConSinBaja();

    if (this.trabAcc.id == 0) {
      this.title = this.api.tr("asignar_trab");
      if (this.empresaCdt && this.empresaCdt.trabajadoresCdt && this.empresaCdt.trabajadoresCdt.length > 0) {
        this.actualizarTrabajadores();
      } else {
        this.api.getTrabajadoresCdt(this.empresaCdt)
          .then(() => this.actualizarTrabajadores());
      }
    } else {
      this.title = this.api.tr("editar_trab");
    }

    this.title += " - " + this.acc.tipo;
  }

  attached() {
    this.api.updateHorasDelTrabajo();
    this.api.updateFormasDeContacto();
    this.api.updateAgentesMateriales();
    this.api.updateParteDelCuerpo();
    this.api.updateLesiones();
    this.api.updateNaturalezaDeLaLesion();
  }

  actualizarTrabajadores() {
    console.log("actualizarTrabajadores", this.empresaCdt.trabajadoresCdt)
    this.trabList = [];
    if (this.empresaCdt && this.empresaCdt.trabajadoresCdt) {
      // Carga los trabajadores de la empresa que NO estén presentes en esta Actividad Preventiva

      for (let tCdt of this.empresaCdt.trabajadoresCdt) {
        if (!tCdt.presente) {
          continue;
        }

        let asignado = this.acc.trabajadores.find((tAcc) => { return (tAcc.trab_id == tCdt.trabajador.id); });
        if (asignado) {
          continue;
        }

        this.trabList.push(tCdt.trabajador);
      }
    }

    this.trabList.sort((a, b) => {
      if (a.nombreCompleto < b.nombreCompleto) { return -1; }
      if (a.nombreCompleto > b.nombreCompleto) { return 1; }
    });
  }

  save() {

    if (!this.acceptEnabled) {
      return;
    }

    this.acceptEnabled = false;
    setTimeout(() => {
      this.acceptEnabled = true;
    }, 2000);


    console.log("trabAcc.fecha_baja", this.trabAcc.fecha_baja);

    let fSuceso = moment(this.acc.fecha, "DD-MM-YYYY");
    let fBaja = moment(this.trabAcc.fecha_baja, "DD-MM-YYYY");
    if (fBaja.isValid() && fBaja < fSuceso) {
      this.errors = this.api.tr("error_acc_fecha_baja") + " (" + this.acc.fecha + ")";
      return;
    }

    if (this.trabAcc.fecha_alta != "") {
      let fAlta = moment(this.trabAcc.fecha_alta, "DD-MM-YYYY");
      if (fAlta <= fBaja) {
        this.errors = this.api.tr("error_acc_fecha_alta_baja");
        return;
      }
    }

    if (this.trabAcc.notas == "") {
      this.errors = this.api.tr("error_acc_desc");
      return;
    }

    let creacion = this.trabAcc.id == 0;
    if (creacion) {
      if (!this.selectedTrab) {
        this.errors = this.api.tr("error_acc_trab");
        return;
      } else {
        this.trabAcc.trab_id = this.selectedTrab.id;
      }
    }

    // Comprbar in-itinere
    if (this.trabAcc.lugar_codigo == 2) {
      let ht = this.api.horasDelTrabajoList.find(ht=> ht.id == this.trabAcc.hora_trabajo_id);
      if (!ht.itinere) {
        this.errors = this.api.tr("error_acc_trab_itinere");
        // this.errors = this.api.tr("error_acc_trab");
        return
      }
    }

    // // Pasar de bool a numero
    // if (this.trabAcc.acc_trafico) {
    //   this.trabAcc.acc_trafico = 1;
    // } else {
    //   this.trabAcc.acc_trafico = 0;
    // }

    console.log(this.trabAcc);
    console.log(this.trabAcc.hora_trabajo_id);

    this.api.saveOne(TrabajadorAccidente, this.trabAcc, 'accidentes_trabajadores', this.trabAcc.id)
      .then(response => {
        console.log("Response: ", response)

        if (response.error) {
          return;
        }

        this.controller.close(true, response);

        console.log("Save Act prev response", response);

        // if (creacion) {
        //   let newCode = response.codigo;
        //   this.parent.editAccidenteByCode(newCode);
        // }
      })
      .catch(error => {
        this.errors = this.api.tr("error_actualizacion") + ": " + error;
      });
  }

  cancel() {
    this.controller.cancel(false);
  }
}
