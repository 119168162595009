import { ActividadPreventiva, getTipoAsistencia, TrabajadorActividadPreventiva } from 'entities/actPrev';
import { autoinject, containerless, observable } from 'aurelia-framework';
import { Trabajador } from 'entities/trabajador';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import { DialogController } from 'aurelia-dialog';
import { EmpresaCdT } from 'entities/centro';
import { TrabajadorCdt } from 'entities/trabajadorCdt';
import moment from 'moment';


@containerless()
@autoinject
export class EyActPrevTrabsForm {

  @observable trabList: Trabajador[] = [];
  @observable selectedTrab: Trabajador;
  @observable trabActPrev: TrabajadorActividadPreventiva;
  @observable tipoAsistenciaList: any = [];

  actividad: ActividadPreventiva;
  empresaCdt: EmpresaCdT;

  title: string;
  errors: string;

  constructor(private api: Api, private ea: EventAggregator, private controller: DialogController) {
    this.tipoAsistenciaList = getTipoAsistencia();
  }

  activate(model) {

    this.actividad = model.actividad;

    switch (this.actividad.tipo_id) {
      case "F": // Designación de personal
        this.api.updateCategoriasPEE();
        this.api.updateCargosPEE();
        this.api.updateTurnosPEE();
        break;
      default:
        ;
    }

    this.trabActPrev = model.trab;
    this.empresaCdt = model.empresaCdt;
    let emp = this.empresaCdt.empresaCached;

    console.log("Comprobando", this.trabActPrev);

    if (!this.trabActPrev.id) {
      this.title = this.api.tr("asignar_trab_act_prev");


      if (this.empresaCdt) {

        let incluir_subcdt = false;

        if (this.actividad.tipo_id == "F") {
          incluir_subcdt = true;
        }

        this.api.getTrabajadoresCdt(this.empresaCdt, incluir_subcdt)
          .then(() => this.actualizarTrabajadores());
      }


      // if (this.empresaCdt && this.empresaCdt.trabajadoresCdt && this.empresaCdt.trabajadoresCdt.length > 0) {
      //   this.actualizarTrabajadores();
      // } else {

      //   // this.api.getTrabajadores(emp)
      //   //   .then(() => this.actualizarTrabajadores(emp));

      //   this.api.getTrabajadoresCdt(this.empresaCdt)
      //     .then(() => this.actualizarTrabajadores());
      // }
    } else {
      this.title = this.api.tr("editar_trab_act_prev");;
    }
  }

  actualizarTrabajadores() {
    console.log("actualizarTrabajadores", this.empresaCdt.trabajadoresCdt)
    let availableTrabs: TrabajadorCdt[] = [];
    if (this.empresaCdt && this.empresaCdt.trabajadoresCdt) {
      // Carga los trabajadores de la empresa que NO estén presentes en esta Actividad Preventiva

      for (let tCdt of this.empresaCdt.trabajadoresCdt) {
        if (!tCdt.presente) {
          continue;
        }
        let asignado = this.actividad.trabajadores.find((tAct) => { return (tAct.trab_id == tCdt.trabajador.id); });
        // console.log("actualizarTrabajadores", t.nombre, t.activo)
        if (asignado) {
          continue;
        }

        availableTrabs.push(tCdt);
      }
    }

    availableTrabs.sort((a, b) => {
      if (a.nombreCompleto < b.nombreCompleto) { return -1; }
      if (a.nombreCompleto > b.nombreCompleto) { return 1; }
    });

    this.trabList = [];
    availableTrabs.forEach((tCdt) => { this.trabList.push(tCdt.trabajador) });

    console.log("trabajadores", this.trabList);
  }

  attached() { }

  save() {

    this.errors = "";

    if (!this.trabActPrev.trab_id) {
      this.trabActPrev.trab_id = this.selectedTrab.id;
    }

    let fAcc = moment(this.actividad.fecha_inicio, "DD-MM-YYYY");

    let fAsig = moment(this.trabActPrev.fecha, "DD-MM-YYYY");
    if (!fAsig.isValid()) {
      this.errors = this.api.tr("error_fecha_asig");
      return;
    }

    if (fAsig < fAcc) {
      this.errors = this.api.tr("error_fecha_anterior_act");
      return;
    }

    if (this.trabActPrev.fecha_desasignacion != "") {
      let fDesasig = moment(this.trabActPrev.fecha_desasignacion, "DD-MM-YYYY");
      if (!fDesasig.isValid()) {
        this.errors = this.api.tr("error_fecha_desasig");
        return;
      }

      if (fDesasig < fAsig) {
        this.errors = this.api.tr("error_fecha_des_anterior_act");
        return;
      }
    }
    // if (!fAsig.isValid() && fBaja < fSuceso) {
    //   this.errors = "La fecha de baja no puede ser anterior a la fecha del suceso (" + this.acc.fecha + ")";
    //   return;
    // }

    this.api.saveOne(TrabajadorActividadPreventiva, this.trabActPrev, 'actividades_preventivas_trabajadores', this.trabActPrev.id)
      .then(response => {
        console.log("Response: ", response)

        if (response.error) {
          return;
        }


        this.controller.close(true, response);

        // console.log("newTrab", response);

        // let newCode = response.codigo;

        // this.ea.publish("trabajador::edit", { empID: this.trabajador.empresa, trabID: newCode });

      })
      .catch(error => {
        this.errors = this.api.tr("error_actualizacion") + ": " + error;
      });
  }

  cancel() {
    this.controller.cancel(false);
  }
}
