import { getGradoLesion, getPosibilidadRepeticion, TrabajadorAccidente } from './../entities/actPrev';
import { autoinject, bindable, containerless, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import { AccidenteTrabEdit } from 'pages/accidente-trab/edit';


@containerless()
@autoinject
export class EyAccTrabInvestigacion {

  @bindable accTrab: TrabajadorAccidente;
  @bindable edit: boolean = false;
  @bindable parent: AccidenteTrabEdit;

  //
  @observable gradoLesionList: any = [];
  @observable probRepeticionList: any = [];

  constructor(private api: Api, private ea: EventAggregator) {

    this.gradoLesionList = getGradoLesion();
    this.probRepeticionList = getPosibilidadRepeticion();
  }

  attached() {
    console.log("attached")
    this.api.updateSubTiposActividadesPreventivas();
    this.api.updateRiesgos();
    this.api.updateFactoresRiesgos();
    this.api.updateConsecuencias();
    this.api.updateNivelesExposicion();
    this.api.updateProbabilidadSuceso();

    this.parent.ready = false;
    // Forzar esperar un poco antes de cambiar cosas
    setTimeout(() => {
      this.parent.ready = true;
      this.parent.saveChanges = false
    }, 2 * 1000);
  }

  detached() {
    console.log("detached")
  }
}
