import { autoinject, bindable, observable } from 'aurelia-framework';

@autoinject
export class EyBool {
  @bindable value: boolean;

  @observable icon: string = 'times';
  @observable color: string = 'text-danger';

  constructor(private element: Element) {
  }

  valueChanged(newValue) {
    this.value = (newValue === true || newValue === 'true' || newValue == 1);
    if (newValue == 3) {
      this.icon = 'question-circle-o';
      this.color = 'text-primary';
    } else {
      if (!this.value) {
        this.icon = 'times';
        this.color = 'text-danger';
      } else {
        this.icon = 'check';
        this.color = 'text-success';
      }
    }
  }
}


