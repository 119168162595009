import { Router } from 'aurelia-router';
import { Accidente, ActividadPreventiva, ActividadPreventivaDup } from './../entities/actPrev';
import { autoinject, bindable, bindingMode, containerless, observable, computedFrom } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import AuthService from 'authService';
import { EmpresaCdT } from 'entities/centro';
import moment from 'moment';
import { EyActPrevForm } from './ey-act-prev-form';
import { EyAccForm } from './ey-acc-form';
import { EyActPrevDupForm } from './ey-act-prev-dup-form';
import { EyMedCorr } from './ey-med-corr';

@containerless()
@autoinject
export class EyCdtGestion {

  @bindable empresaCdt: EmpresaCdT;
  @bindable actividades: ActividadPreventiva[] = [];
  @bindable actPrevSeleccion: string = "";
  @bindable accSeleccion: string = "";
  @bindable accidentes: Accidente[] = [];

  @observable terminado: boolean = false;
  @observable fechaDesdeAct: moment.Moment;
  @observable fechaHastaAct: moment.Moment;
  @observable fechaDesdeAcc: moment.Moment;
  @observable fechaHastaAcc: moment.Moment;
  @observable medCorrTipo: number = 0;
  @observable tab: string = "act-prev";

  mcWidget: EyMedCorr;


  fechaSub: Subscription;
  docSub: Subscription;

  constructor(private router: Router, private api: Api, private ea: EventAggregator, private auth: AuthService) {
    this.fechaHastaAct = moment();
    this.fechaDesdeAct = this.api.getStartTime(this.fechaHastaAct);

    this.fechaHastaAcc = moment();
    this.fechaDesdeAcc = this.api.getStartTime(this.fechaHastaAcc);
  }

  attached() {

    this.fechaSub = this.ea.subscribe('filter_fechas', (data) => {

      if (this.tab == "act-prev") {
        this.fechaDesdeAct = data.f_desde;
        this.fechaHastaAct = data.f_hasta;
      } else if (this.tab == "accidentes") {
        this.fechaDesdeAcc = data.f_desde;
        this.fechaHastaAcc = data.f_hasta;
      }

      this.selectTab(this.tab);
    });

    this.docSub = this.ea.subscribe("select::doc", (data) => {
      console.log("select::doc", data);

      if (data.owner instanceof Accidente) {
        this.editAccidente(data.owner, data.filterType);
      }
    });

    // this.getActividadesPreventivas().then(() => {
    //   if (this.auth.authData.cdt_gestion_prl && this.empresaCdt.ct.vis_prl) {
    //     this.tab = "act-prev";
    //   } else if (this.auth.authData.cdt_gestion_resumen_med_corr) {
    //     this.tab = "act-prev-med-corr";
    //   } else if (this.auth.authData.cdt_gestion_accidentes && this.empresaCdt.ct.vis_acc) {
    //     this.tab = "accidentes";
    //   }

    //   this.selectTab(this.tab);
    // });



  }

  detached() {
    this.fechaSub.dispose();
    this.docSub.dispose();
  }

  empresaCdtChanged() {
    console.log("empresaCdtChanged", this.empresaCdt);
    // this.getActividadesPreventivas().then(() => {
      if (this.auth.authData.cdt_gestion_prl && this.empresaCdt.ct.vis_prl) {
        this.tab = "act-prev";
      } else if (this.auth.authData.cdt_gestion_resumen_med_corr) {
        this.tab = "act-prev-med-corr";
      } else if (this.auth.authData.cdt_gestion_accidentes && this.empresaCdt.ct.vis_acc) {
        this.tab = "accidentes";
      }

      this.selectTab(this.tab);
    // });
  }

  getActividadesPreventivas(): Promise<any> {

    if (!this.empresaCdt) {
      console.log("empresaCdt sin definir")
      return;
    }

    let params = {
      empresa: this.empresaCdt.empresa_id,
      empresa_cdt: this.empresaCdt.id,
      f_desde: this.api.getFechaFormatted(this.fechaDesdeAct),
      f_hasta: this.api.getFechaFormatted(this.fechaHastaAct),
    }

    return this.api.getList(ActividadPreventiva, 'actividades_preventivas', params)
      .then(items => {
        if (items) {
          this.terminado = true;
          this.actividades = items;
        }
      })
  }

  getAccidentes() {

    if (!this.empresaCdt) {
      return;
    }

    let params = {
      empresa: this.empresaCdt.empresa_id,
      empresa_cdt: this.empresaCdt.id,
      f_desde: this.api.getFechaFormatted(this.fechaDesdeAcc),
      f_hasta: this.api.getFechaFormatted(this.fechaHastaAcc),
    }

    this.api.getList(Accidente, 'accidentes', params)
      .then(items => {
        if (items) {
          this.terminado = true;
          this.accidentes = items;
        }
      })
  }

  // updateTable() {
  //   this.api.loadDataTable("trabajadores")
  //     .then(table => {
  //       this.table = table;
  //     });
  // }

  editActPrevByCode(actprev: number) {
    let params = {
      empID: this.empresaCdt.empresa_id,
      empCdt: this.empresaCdt.id,
      actPrevID: actprev
    };
    this.router.navigateToRoute('actPrevEdit', params);
  }

  editActPrev(actPrev: ActividadPreventiva) {
    // this.ea.publish("trabajador_cdt::edit",
    //   {
    //     empCdt: trab.empresa_cdt_id,
    //     trabCdtID: trab.id
    //   });
    let params = {
      empID: this.empresaCdt.empresa_id,
      empCdt: this.empresaCdt.id,
      actPrevID: actPrev.id
    };
    this.router.navigateToRoute('actPrevEdit', params);
  }

  duplicarActPrev(actPrev: ActividadPreventiva) {

    let actprevDup = new ActividadPreventivaDup();
    actprevDup.id = actPrev.id;
    actprevDup.duplicarSitRieBool = false;
    actprevDup.duplicarTrabBool = true;

    this.api.showEditorDialog({ viewModel: EyActPrevDupForm, model: { parent: this, actprev: actPrev, actprevDup: actprevDup } })
      .then((response) => {
        if (response && !response.wasCancelled) {
          this.getActividadesPreventivas();
        }
      });

  }


  editAccidenteByCode(accidente: number) {
    let params = {
      empID: this.empresaCdt.empresa_id,
      empCdt: this.empresaCdt.id,
      actPrevID: accidente
    };
    this.router.navigateToRoute('accidenteEdit', params);
  }


  editAccidente(acc: Accidente, filterType?: any) {
    let params = {
      empID: this.empresaCdt.empresa_id,
      empCdt: this.empresaCdt.id,
      accidenteID: acc.id,
      filterType: filterType,
    };
    this.router.navigateToRoute('accidenteEdit', params);
  }


  selectTab(tab: string) {
    this.tab = tab;
    if (this.tab == "act-prev") {
      this.getActividadesPreventivas();
    } else if (this.tab == "accidentes") {
      this.getAccidentes();
    } else if (this.tab == "act-prev-med-corr") {
      console.log("Actualizando ", this.mcWidget)
      if (this.mcWidget) {
        this.mcWidget.updateData();
      }
    }
  }

  newActPrev() {
    if (!this.empresaCdt) {
      return;
    }

    let ap = new ActividadPreventiva();
    ap.empresa_cdt_id = this.empresaCdt.id;
    ap.cdt_id = this.empresaCdt.ct.id;
    ap.cdt = this.empresaCdt.nombre;

    this.api.showEditorDialog({ viewModel: EyActPrevForm, model: { parent: this, actprev: ap } })
      .then((response) => {
        if (response && !response.wasCancelled) {
          console.log("newActPrev", response)
          this.getActividadesPreventivas();
        }
      });
  }

  newAccidente() {
    if (!this.empresaCdt) {
      return;
    }

    let acc = new Accidente();
    acc.cdt_id = this.empresaCdt.ct.id;
    acc.cdt = this.empresaCdt.nombre;

    this.api.showEditorDialog({ viewModel: EyAccForm, model: { parent: this, acc: acc, empresaCdt: this.empresaCdt } })
      .then((response) => {
        if (response && !response.wasCancelled) {
          console.log("newAcc", response)
          this.getAccidentes();
        }
      });
  }
}
