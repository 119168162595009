import { EyDialog } from './ey-dialog';
import { ActividadPreventiva, SubtipoActividadPreventiva } from 'entities/actPrev';
import { autoinject, containerless, observable, computedFrom } from 'aurelia-framework';
import { Trabajador } from 'entities/trabajador';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import { DialogController } from 'aurelia-dialog';
import { EyCdtGestion } from './ey-cdt-gestion';
import AuthService from 'authService';
import moment from 'moment';
import * as _ from 'lodash';

@containerless()
@autoinject
export class EyActPrevForm extends EyDialog {

  @observable actprev: ActividadPreventiva;
  title: string;
  @observable errors: string;
  parent: EyCdtGestion;

  @observable trabList: Trabajador[] = [];
  @observable selectedTecnico: Trabajador;
  // @observable subTiposActPrev: SubtipoActividadPreventiva[] = [];

  constructor(api: Api, element: Element, private ea: EventAggregator, private controller: DialogController, private auth: AuthService) {
    super(api, element);
  }

  activate(model) {
    this.actprev = model.actprev;
    this.parent = model.parent;

    if (this.actprev.id == 0) {
      this.title = "Nueva Actividad Preventiva";
    } else {
      this.title = "Editar Actividad Preventiva";
    }

    if (this.parent.empresaCdt && this.parent.empresaCdt.trabajadoresCdt && this.parent.empresaCdt.trabajadoresCdt.length > 0) {
      this.actualizarTrabajadores();
    } else {
      this.api.getTrabajadoresCdt(this.parent.empresaCdt, true)
        .then(() => this.actualizarTrabajadores());
    }
  }

  attached() {
    this.api.updateSubTiposActividadesPreventivas();
  }

  save() {

    if (!this.acceptEnabled) {
      return;
    }

    this.acceptEnabled = false;
    setTimeout(() => {
      this.acceptEnabled = true;
    }, 2000);



    if (this.actprev.nombre == "") {
      this.errors = this.api.tr("error_nombre");
      return;
    }

    let fInicio = moment(this.actprev.fecha_inicio, "DD-MM-YYYY");
    if (!fInicio.isValid()) {
      this.errors = this.api.tr("error_fecha_inicio");
      return;
    }

    let fFin = moment(this.actprev.fecha_fin, "DD-MM-YYYY");
    if (fFin.isValid() && fInicio > fFin) {
      this.errors = this.api.tr("error_fecha_inicio_fin");
      return;
    }


    if (this.selectedTecnico) {
      this.actprev.tecnico_id = this.selectedTecnico.id;
    } else {
      this.actprev.tecnico_id = 0;
    }

    if (this.actprev.tecnico_id == 0) {
      this.errors = this.api.tr("error_tecnico");
      return;
    }

    let creacion = this.actprev.id == 0;

    this.api.saveOne(ActividadPreventiva, this.actprev, 'actividades_preventivas', this.actprev.id)
      .then(response => {
        console.log("Response: ", response)

        if (response.error) {
          return;
        }
        // if (response.error) {
        // this.errors = response.error;
        // return;
        // }

        this.controller.close(true, response);

        console.log("Save Act prev response", response);

        if (creacion) {
          let newCode = response.codigo;
          this.parent.editActPrevByCode(newCode);
        }
      })
      .catch(error => {
        this.errors = this.api.tr("error_actualizacion") + ": " + error;
      });
  }

  cancel() {
    this.controller.cancel(false);
  }

  actualizarTrabajadores() {
    console.log("actualizarTrabajadores", this.parent.empresaCdt.trabajadoresCdt)
    this.trabList = [];
    if (this.parent.empresaCdt && this.parent.empresaCdt.trabajadoresCdt) {
      // Carga los trabajadores de la empresa que NO estén presentes en esta Actividad Preventiva

      for (let tCdt of this.parent.empresaCdt.trabajadoresCdt) {
        if (!tCdt.presente) {
          continue;
        }

        // TODO: comprobar si es Técnico PRL??
        let trab = tCdt.trabajador;
        if (this.actprev.tecnico_id == trab.id) {
          this.selectedTecnico = trab;
        }

        this.trabList.push(trab);
      }
    }

    this.trabList.sort((a, b) => {
      if (a.nombreCompleto < b.nombreCompleto) { return -1; }
      if (a.nombreCompleto > b.nombreCompleto) { return 1; }
    });
  }

  @computedFrom("actprev.tipo_id", "api.subTiposActPrev")
  get subTiposActPrev(): SubtipoActividadPreventiva[] {
    let subtipos: SubtipoActividadPreventiva[] = [];

    this.api.subTiposActPrev.forEach(st => {
      if (_.includes(st.acts, this.actprev.tipo_id)) {
        subtipos.push(st);
      }
    });

    return subtipos;
  }
}
