import { Accidente, getTiposAcc } from './../entities/actPrev';
import { autoinject, containerless, observable } from 'aurelia-framework';
import { Trabajador } from 'entities/trabajador';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import { DialogController } from 'aurelia-dialog';
import { EyCdtGestion } from './ey-cdt-gestion';
import { EmpresaCdT } from 'entities/centro';
import { EyDialog } from './ey-dialog';

@containerless()
@autoinject
export class EyAccForm extends EyDialog {

  @observable acc: Accidente;
  title: string;
  @observable errors: string;
  @observable tipoSuceso: any = [];
  parent: EyCdtGestion;
  empresaCdt: EmpresaCdT;
  @observable trabList: Trabajador[] = [];
  @observable selectedTecnico: Trabajador;


  constructor(api: Api, element: Element, private ea: EventAggregator, private controller: DialogController) {
    super(api, element);
  }

  activate(model) {
    this.acc = model.acc;
    this.parent = model.parent;
    this.empresaCdt = model.empresaCdt;
    this.tipoSuceso = getTiposAcc();

    if (this.acc.id == 0) {
      this.title = "Nuevo Accidente/Incidente";
    } else {
      this.title = "Editar " + this.acc.tipo;
    }

    if (this.empresaCdt && this.empresaCdt.trabajadoresCdt && this.empresaCdt.trabajadoresCdt.length > 0) {
      this.actualizarTrabajadores();
    } else {

      // this.api.getTrabajadores(emp)
      //   .then(() => this.actualizarTrabajadores(emp));

      this.api.getTrabajadoresCdt(this.empresaCdt)
        .then(() => this.actualizarTrabajadores());
    }


  }

  attached() {
  }

  actualizarTrabajadores() {
    console.log("actualizarTrabajadores", this.empresaCdt.trabajadoresCdt)
    this.trabList = [];
    if (this.empresaCdt && this.empresaCdt.trabajadoresCdt) {
      // Carga los trabajadores de la empresa que NO estén presentes en esta Actividad Preventiva

      for (let tCdt of this.empresaCdt.trabajadoresCdt) {
        if (!tCdt.presente) {
          continue;
        }

        // TODO: comprobar si es Técnico PRL??
        let trab = tCdt.trabajador;
        if (this.acc.tecnico_id == trab.id) {
          this.selectedTecnico = trab;
        }

        this.trabList.push(trab);
      }
    }

    this.trabList.sort((a, b) => {
      if (a.nombreCompleto < b.nombreCompleto) { return -1; }
      if (a.nombreCompleto > b.nombreCompleto) { return 1; }
    });
  }

  save() {

    if (!this.acceptEnabled) {
      return;
    }
    this.acceptEnabled = false;
    setTimeout(() => {
      this.acceptEnabled = true;
    }, 2000);


    if (this.acc.nombre == "") {
      this.errors = this.api.tr("error_nombre");
      return;
    }

    if (this.acc.fecha == "") {
      this.errors = this.api.tr("error_fecha_suceso");
      return;
    }

    if (this.acc.notas == "") {
      this.errors = this.api.tr("error_acc_desc");
      return;
    }

    if (this.selectedTecnico) {
      this.acc.tecnico_id = this.selectedTecnico.id;
    }

    let creacion = this.acc.id == 0;

    this.api.saveOne(Accidente, this.acc, 'accidentes', this.acc.id)
      .then(response => {
        console.log("Response: ", response)

        if (response.error) {
          return;
        }
        // if (response.error) {
        // this.errors = response.error;
        // return;
        // }

        this.controller.close(true, response);

        console.log("Save Act prev response", response);

        if (creacion) {
          let newCode = response.codigo;
          this.parent.editAccidenteByCode(newCode);
        }
      })
      .catch(error => {
        this.errors = this.api.tr("error_actualizacion") + ": " + error;
      });
  }

  cancel() {
    this.controller.cancel(false);
  }

  get tipoSucesoDesc(): string {
    let desc = "";
    for (let ts of this.tipoSuceso) {
      if (ts.id == this.acc.tipo_id) {
        desc = ts.desc;
        break;
      }
    }
    return desc;
  }
}
