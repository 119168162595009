import { Router } from 'aurelia-router';
import { Accidente, TrabajadorAccidente } from './../entities/actPrev';
import { autoinject, bindable, bindingMode, containerless, observable, computedFrom } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import AuthService from 'authService';
import moment from 'moment';
import { Empresa } from 'entities/empresa';

@containerless()
@autoinject
export class EyDelta {

  @bindable empresa: Empresa;
  @observable accidentesTrab: TrabajadorAccidente[] = [];
  ccc: string;
  fecha: string;
  tipoBaja: number;
  table: any;
  tipo: string;

  fechaSub: Subscription;

  constructor(private router: Router, private api: Api, private ea: EventAggregator, private auth: AuthService) {
  }


  deactivate() {
  }

  attached() {
    this.fechaSub = this.ea.subscribe('filter_delta', (data) => {

      this.tipo = data.tipoDeltaConta;
      this.fecha = data.fecha;
      this.ccc = data.ccc;
      this.tipoBaja = data.tipoBaja;

      this.getTrabajadoresAccidentados();
    });
  }

  detached() {
    if (this.fechaSub) {
      this.fechaSub.dispose();
    }
  }

  getTrabajadoresAccidentados() {
    // this.api.getTrabajadoresAccidente(this.empresaCdt);

    this.api.getTrabajadoresDelta(this.tipo, this.empresa.id, this.fecha, this.ccc).then((accTrabs) => {
      this.accidentesTrab = accTrabs;
    });
  }

  PAT_DELTA(accTrab: TrabajadorAccidente) {
    this.api.downloadPAT("DELTA", this.empresa.id, this.fecha, this.ccc, accTrab.id).then((xml) => {
      console.log("xml", xml);
    });
  }

  PAT_CONTA(accTrab: TrabajadorAccidente) {
    this.api.downloadPAT("CONTA", this.empresa.id, this.fecha, this.ccc, accTrab.id).then((xml) => {
      console.log("xml", xml);
    });
  }

  RATSB_DELTA() {
    this.api.downloadRATSB("DELTA", this.empresa.id, this.fecha, this.ccc);
  }
  RATSB_CONTA() {
    this.api.downloadRATSB("CONTA", this.empresa.id, this.fecha, this.ccc);
  }

  isBaja(accTrab: TrabajadorAccidente): boolean {

    console.log("isBaja", accTrab.baja_id, this.tipoBaja);

    if (!accTrab.baja_id) {
      return this.tipoBaja == 0;
    }

    return accTrab.baja_id == this.tipoBaja;
  }
}
