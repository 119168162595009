import { getTipoReunion } from 'entities/actPrev';
import { ActividadPreventiva } from 'entities/actPrev';
import { autoinject, bindable, bindingMode, containerless, observable, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import AuthService from 'authService';
import { EmpresaCdT } from 'entities/centro';
import { ActividadPreventivaEdit } from 'pages/actividadPreventiva/edit';

@containerless()
@autoinject
export class EyActPrevReunion {
  @bindable empresaCdt: EmpresaCdT;
  @bindable actPrev: ActividadPreventiva;
  @bindable parent: ActividadPreventivaEdit;

  @observable fasesReunion: any = [];

  constructor(private element: Element, private api: Api, private ea: EventAggregator, private auth: AuthService) {
  }

  attached() {
    this.fasesReunion = getTipoReunion();
    this.api.updateTiposComision();
  }
}
