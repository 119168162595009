
import { MedidaCorrectora, MedidaPreventiva } from 'entities/actPrev';
import { autoinject, containerless, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import { DialogController } from 'aurelia-dialog';
import moment from 'moment';
import AuthService from 'authService';
import { EyMedCorr } from './ey-med-corr';


@containerless()
@autoinject
export class EyActPrevMcForm {

  @observable mp: MedidaPreventiva;
  parent: EyMedCorr;

  title: string;
  errors: string;

  constructor(private api: Api, private auth: AuthService, private ea: EventAggregator, private controller: DialogController) {
  }

  activate(model) {
    this.parent = model.parent;
    this.mp = model.mp;
    this.title = this.api.tr("editar_mp");
  }


  attached() { }

  save() {

    this.errors = "";

    if (this.mp.mp_especifica_valor == '1' || this.mp.mp_especifica_valor == '2') {
    } else {
      if (this.mp.mp_especifica.length < 3) {
        this.errors = this.api.tr("mc_desc_err");
        return;
      }
    }

    if (this.mp.requiereFechaImp && this.mp.fecha_implementacion == "") {
      this.errors = this.api.tr("mc_fecha_fin_err");
      return;
    }

    if (this.mp.mp_especifica_valor == "0") { // Si es NO, borramos la fecha
      this.mp.fecha_implementacion = "";
    }

    // if (this.mp.mp_especifica_valor == '0' || this.mp.mp_especifica_valor == '3') {

    let fInicio;
    if (this.mp.fecha_inicio != "") {
      fInicio = moment(this.mp.fecha_inicio, "DD-MM-YYYY");
      if (!fInicio.isValid()) {
        this.errors = this.api.tr("mc_fecha_err1");
        return;
      }

      let fPrevista = moment(this.mp.fecha_prevista, "DD-MM-YYYY");
      if (!fPrevista.isValid()) {
        this.errors = this.api.tr("mc_fecha_err2");
        return;
      }

      if (fInicio > fPrevista) {
        this.errors = this.api.tr("mc_fecha_err3");
        return;
      }
    }

    if (this.mp.fecha_implementacion != "") {
      let fFinalizacion = moment(this.mp.fecha_implementacion, "DD-MM-YYYY");
      if (!fFinalizacion.isValid()) {
        this.errors = this.api.tr("mc_fecha_err4");
        return;
      }

      if (fInicio > fFinalizacion) {
        this.errors = this.api.tr("mc_fecha_err5");
        return;
      }

      if (fFinalizacion > moment()) {
        this.errors = this.api.tr("mc_fecha_err6");
        return;
      }
    }
    // }

    this.parent.guardarMp(this.mp)
      .then((response) => {
        this.controller.close(true, response);
      })
      .catch(error => {
        this.errors = "Error:" + error;
      });
  }

  cancel() {
    this.controller.cancel(false);
  }


  /*
    save() {

    this.errors = "";

    if (this.mc.inc_especifica_valor == '1' || this.mc.inc_especifica_valor == '2') {
    } else {
      if (this.mc.inc_especifica.length < 3) {
        this.errors = this.api.tr("mc_desc_err");
        return;
      }
    }

    if (this.mc.requiereFechaImp && this.mc.fecha_implementacion == "") {
      this.errors = this.api.tr("mc_fecha_fin_err");
      return;
    }

    if (this.mc.inc_especifica_valor == '0' || this.mc.inc_especifica_valor == '3') {

      let fInicio;
      if (this.mc.fecha_inicio != "") {
        fInicio = moment(this.mc.fecha_inicio, "DD-MM-YYYY");
        if (!fInicio.isValid()) {
          this.errors = this.api.tr("mc_fecha_err1");
          return;
        }

        let fPrevista = moment(this.mc.fecha_prevista, "DD-MM-YYYY");
        if (!fPrevista.isValid()) {
          this.errors = this.api.tr("mc_fecha_err2");
          return;
        }

        if (fInicio > fPrevista) {
          this.errors = this.api.tr("mc_fecha_err3");
          return;
        }
      }

      if (this.mc.fecha_implementacion != "") {
        let fFinalizacion = moment(this.mc.fecha_implementacion, "DD-MM-YYYY");
        if (!fFinalizacion.isValid()) {
          this.errors = this.api.tr("mc_fecha_err4");
          return;
        }

        if (fInicio > fFinalizacion) {
          this.errors = this.api.tr("mc_fecha_err5");
          return;
        }
      }
    }

    this.parent.guardar(this.mc)
      .then((response) => {
        this.controller.close(true, response);
      })
      .catch(error => {
        this.errors = "Error:" + error;
      });
  }
  */
}
