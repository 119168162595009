import { autoinject, Container, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { Api, GetAPI } from 'utils/api';

export class PageAction {
  key: string;
  icon: string;

  href: string;
  isMenu: boolean;
  menu: string;

  constructor(key: string, icon: string) {
    this.key = key;
    this.icon = icon;
  }
}

@autoinject()
export class EyBasePage {
  @bindable
  title: string;

  @bindable
  description: string;

  protected router: Router;
  protected eventAggregator: EventAggregator;
  // protected api: Api;
  protected dialogService: DialogService;

  private actions: PageAction[] = [];

  constructor(router: Router) {
    // this.api = GetAPI();
    this.eventAggregator = Container.instance.get(EventAggregator);
    this.dialogService = Container.instance.get(DialogService);
    this.router = router;


    // console.log("EyBasePage", this.api);
  }

  attached() {
    // this.router.currentInstruction.config.navModel.settings.actions = this.actions;

    if (!this.title) {
      // this.title = this.api.tr(this.router.currentInstruction.config.title);
      this.title = this.router.currentInstruction.config.title;
    }

    if (!this.description) {
      // this.description = this.api.tr(this.router.currentInstruction.config.settings.description);
      this.description = this.router.currentInstruction.config.settings.description;
    }

    console.log("EyBasePage attached:", this.title, this.description);
  }

  // activated() {
  //   if (!this.title) {
  //     this.title = this.api.tr(this.router.currentInstruction.config.title);
  //   }

  //   if (!this.description) {
  //     this.description = this.api.tr(this.router.currentInstruction.config.settings.description);
  //   }
  //   console.log("EyBasePage activated:", this.title, this.description);
  // }

  addAction(action: PageAction) {
    this.actions.push(action);
  }

  // setRouteTitle(title: string) {
  //   if (!this.router.currentInstruction)
  //     return
  //   this.router.currentInstruction.config.title = title;
  // }
}
