import { ActividadPreventiva, getGestionActGestion, getGestionActPreventiva, getGestionActSanitaria, getTiposAcc } from 'entities/actPrev';
import { autoinject, bindable, bindingMode, containerless, observable, computedFrom } from 'aurelia-framework';
import { Api } from 'utils/api';
import { EmpresaCdT } from 'entities/centro';
import { ActividadPreventivaEdit } from 'pages/actividadPreventiva/edit';

@containerless()
@autoinject
export class EyActPrevGestDoc {
  @bindable empresaCdt: EmpresaCdT;
  @bindable actPrev: ActividadPreventiva;
  @bindable parent: ActividadPreventivaEdit;

  @observable actPreventivas: any = [];
  @observable actSanitarias: any = [];
  @observable actGestiones: any = [];
  @observable accTipos: any = [];

  constructor(private api: Api) {
    this.actPreventivas = getGestionActPreventiva();
    this.actSanitarias = getGestionActSanitaria();
    this.actGestiones = getGestionActGestion();
    this.accTipos = getTiposAcc();
  }

  attached() {
    this.api.updateSubTiposActividadesPreventivas();
  }
}
