import { ActividadPreventivaDup } from './../entities/actPrev';
import { ActividadPreventiva, SubtipoActividadPreventiva } from 'entities/actPrev';
import { autoinject, containerless, observable } from 'aurelia-framework';
import { Trabajador } from 'entities/trabajador';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import { DialogController } from 'aurelia-dialog';
import { EyCdtGestion } from './ey-cdt-gestion';
import AuthService from 'authService';
import moment from 'moment';

@containerless()
@autoinject
export class EyActPrevDupForm {

  @observable actprev: ActividadPreventiva;
  @observable actprevDup: ActividadPreventivaDup;
  @observable enableChecks: boolean;

  title: string;
  @observable errors: string;
  parent: EyCdtGestion;

  constructor(private api: Api, private ea: EventAggregator, private controller: DialogController, private auth: AuthService) {
  }

  activate(model) {
    this.actprev = model.actprev;
    this.actprevDup = model.actprevDup;
    this.parent = model.parent;
    this.enableChecks = this.actprev.puedeDuplicarChecks(this.auth.authData);

    this.title = this.api.tr("dup_act_prev");
  }

  attached() {
  }

  save() {


    this.api.saveOne(ActividadPreventivaDup, this.actprevDup, 'actividades_preventivas_duplica', this.actprev.id)
      .then(response => {
        console.log("Response: ", response)

        if (response.error) {
          return;
        }
        // if (response.error) {
        // this.errors = response.error;
        // return;
        // }

        this.controller.close(true, response);

        console.log("Save Act prev response", response);

      })
      .catch(error => {
        this.errors = this.api.tr("error_actualizacion") + ": " + error;
      });
  }

  cancel() {
    this.controller.cancel(false);
  }
}
