import { ActividadPreventivaEdit } from './../pages/actividadPreventiva/edit';
import { EyActPrevVisitaInfo } from './ey-act-prev-visita-info';
import { getTipoMediosPE, MedidaCorrectora, TipoActividadPreventiva } from 'entities/actPrev';
import { ActividadPreventiva } from 'entities/actPrev';
import { autoinject, bindable, bindingMode, containerless, observable, computedFrom } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import AuthService from 'authService';
import { EmpresaCdT } from 'entities/centro';
import moment from 'moment';
import { Trabajador } from 'entities/trabajador';
import { Empresa } from 'entities/empresa';

@containerless()
@autoinject
export class EyActPrevPlanEmergencia {
  @bindable empresaCdt: EmpresaCdT;
  @bindable actPrev: ActividadPreventiva;
  @bindable parent: ActividadPreventivaEdit;

  @observable tipoMediosPE: any = [];

  maintab: string = "pee";
  tab: string = "actividad";

  constructor(private element: Element, private api: Api, private ea: EventAggregator, private auth: AuthService) {
  }

  attached() {
    console.log("EyActPrevPlanEmergencia attached", this.empresaCdt);

    this.tipoMediosPE = getTipoMediosPE();

    this.api.updateActividadesCt();
    this.api.updateLugaresCt();
    this.api.updateMensajesCt();

    this.parent.getTrabajadores();
    this.parent.getEmpresas();
  }

  selectMainTab(tab: string) {
    this.maintab = tab;

    if (this.maintab == "pee") {
      this.selectTab("actividad");
    } else if (this.maintab == "simulacro") {
      this.selectTab("situaciones");
    }
  }

  selectTab(tab: string) {
    this.tab = tab;

    if (this.tab == "actividad") {
    } else if (this.tab == "medios") {
    } else if (this.tab == "designaciones") {
    } else if (this.tab == "situaciones") {
    } else if (this.tab == "simulacro") {
    }
  }
}
