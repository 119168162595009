import { ActividadPreventiva, TrabajadorActividadPreventiva } from 'entities/actPrev';
import { autoinject, bindable, bindingMode, containerless, observable, computedFrom } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import AuthService from 'authService';
import { EyActPrevTrabsForm } from './ey-act-prev-trabs-form';
import { EmpresaCdT } from 'entities/centro';

@containerless()
@autoinject
export class EyActPrevTrabs {

  @bindable empresaCdt: EmpresaCdT;
  @bindable actividad: ActividadPreventiva;
  table: any;

  constructor(private api: Api, private ea: EventAggregator, private auth: AuthService) {
  }

  getTrabajadores() {
    this.api.getTrabajadoresActPrev(this.empresaCdt, this.actividad);
  }

  asignarTrab() {
    let trab = new TrabajadorActividadPreventiva();
    trab.actividad_id = this.actividad.id;
    this.editTrab(trab);
  }

  editTrab(trab: TrabajadorActividadPreventiva) {
    this.api.showEditorDialog({ viewModel: EyActPrevTrabsForm, model: { actividad: this.actividad, empresaCdt: this.empresaCdt, trab: trab } })
      .then((response) => {
        if (response && !response.wasCancelled) {
          this.getTrabajadores();
        }
      });
  }

  eliminarTrab(trab: TrabajadorActividadPreventiva) {

    if (this.actividad.tipo_id == "F") { //PEE
      if (trab.pee_cargo_id || trab.pee_categoria_id || trab.pee_turno_id) {
        this.api.alert(this.api.tr("trab_quitar_error"), this.api.tr("trab_quitar_error_act"));
        return;
      }
    }

    this.api.confirm(this.api.tr("trab_quitar"), this.api.tr("trab_act_quitar_conf"))
      .then(b => {
        if (!b)
          return;

        return this.api.removeOne(TrabajadorActividadPreventiva, 'actividades_preventivas_trabajadores', trab.id)
          .then(() => {
            this.getTrabajadores();
          });
      });
  }
}
